var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var skin = "/skins/bmartigrafiche";
setResponsive({ "mobile": 640, "tablet": 1366, "desktop": Infinity });
$(function () {
    if (exists("#home-container")) {
        homePage();
    }
    else if (exists("#cart-container")) {
        cartPage();
    }
    else if (exists("#catalog-container")) {
        catalogPage();
    }
    else if (exists("#checkout-container")) {
        checkoutPage();
    }
    else if (exists("#product-container")) {
        productPage();
    }
    else if (exists("#profile-container")) {
        profilePage();
    }
    else if (exists("#wishlist-container")) {
        wishlistPage();
    }
    if (exists(".subscribe-container")) {
        subscribeComponent();
    }
    if (exists(".product-container")) {
        productComponent();
    }
    if (exists("header")) {
        headerComponent();
    }
    if (exists("#main-menu, #mobile-menu")) {
        menuComponent();
    }
    if (exists(".subscribe-newsletter")) {
        newsletterComponent();
    }
    if (exists("#contact-form")) {
        contactComponent();
    }
    if (exists("#stationery-form")) {
        stationeryComponent();
    }
    if (exists(".swiper-container")) {
        swiperComponent();
    }
    if (exists(".popup-container")) {
        popupComponent();
    }
    setupVideoEmbeds();
});
function isIOS() {
    var iOSPlatforms = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];
    // @ts-ignore navigator.platform exists on older iOS
    var platformIsIOS = navigator.platform && iOSPlatforms.includes(navigator.platform);
    return (platformIsIOS) ||
        /* fallback to user agent sniffing */ (/iPad|iPhone|iPod/.test(navigator.userAgent)) ||
        /* iPad on iOS 13 detection */ (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}
// Other scripts to be called on every page
$(function () {
    initInputMasks();
    initForceUpperCase();
    initToggle();
    initTabs();
    initSortableTables();
    initSearch();
    initEventEmitters();
    initMultiStep();
    initLightbox();
    initTelDropdowns();
    initBackgroundImageLazyLoad();
    $('a[href*="#"]').on('click', function (event) {
        event.preventDefault();
        var href = $(this).attr('href');
        if (window.location.pathname === '/it/') {
            var hash_1 = "#".concat($(this).attr('href').split('#')[1]);
            scrollToElement(hash_1).then(function () { return window.location.hash = hash_1; });
        }
        else {
            window.location.href = href;
        }
    });
    if (window.location.hash) {
        scrollToElement(window.location.hash);
    }
    $(document)
        .on('set-currency', function (event, currency) {
        event.preventDefault();
        var currentCurrency = checkCookie('currency');
        if (currency != currentCurrency) {
            createCookie('currency', currency);
            window.location.reload();
        }
    })
        .on('toggle-page-scroll', function () {
        if (pageIsScrollable()) {
            blockPageScroll();
        }
        else {
            restorePageScroll();
        }
    });
    var measureHeaderHeight = function () {
        var height = $('header').toArray().reduce(function (height, header) { return height + $(header).outerHeight(); }, 0);
        document.documentElement.style.setProperty('--header-height', height + 'px');
    };
    $(window).on('resize orientationchange', measureHeaderHeight);
    var intervalId = setInterval(function () {
        if (document.readyState == 'complete') {
            measureHeaderHeight();
            clearInterval(intervalId);
        }
    }, 200);
});
function pushGTMEventWithCallback(timeout, options) {
    var _a;
    var dataLayer = (_a = window.dataLayer) !== null && _a !== void 0 ? _a : [];
    var callbackExecuted = false;
    dataLayer.push(__assign(__assign({}, options), (options.eventCallback
        ? {
            eventCallback: GTMCallback(function () {
                if (!callbackExecuted) {
                    callbackExecuted = true;
                    options.eventCallback();
                }
            })
        }
        : {})));
    if (options.eventCallback) {
        setTimeout(function () {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}
