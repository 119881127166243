function popupComponent() {
    const popups = $('.popup-container').toArray();

    const openPopup = (index: number) => {
        const popup = popups[index];
        if (popup) {
            const cookieName = 'popup-' + popup.dataset.code;
            const canShowPopup =
                !checkCookie(cookieName) &&
                !window.location.href.includes('checkout') &&
                !window.location.href.includes('login');
    
            if (canShowPopup) {
                blockPageScroll();

                const closePopup = () => {
                    restorePageScroll();
                    createCookie(cookieName, 'true');
                    popup.remove();
                    setTimeout(() => openPopup(index + 1), 10_000);
                }

                popup.querySelector('.popup-close')?.addEventListener('click', (event) => {
                    event.preventDefault();
                    closePopup();
                });

                const link = popup.querySelector<HTMLAnchorElement>('a.btn');
                if (link) {
                    popup.querySelector('img')?.addEventListener('click', (event) => {
                        event.preventDefault();
                        closePopup();
                        window.location.href = link.href;
                    });
                    
                    link.addEventListener('click', (event) => {
                        event.preventDefault();
                        closePopup();
                        window.location.href = link.href;
                    });
                }
                
                popup.classList.remove('hide');
            }
            else {
                setTimeout(() => openPopup(index + 1), 10_000);
            }
        }
    };

    setTimeout(() => openPopup(0), 15_000);
}